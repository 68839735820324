<template>
    <div class="medicalReport-main">
        <div class="main-header">
            <div class="head-item" @click="seeSearch">
                <p class="title"><i class="icon"></i><span>{{$t("pe.name")}}</span></p>
                <p class="txt">{{$t("pe.subMedical")}}</p>
                <div class="header-btn">
                    {{$t("pe.promptlyQuery")}}
                </div>
            </div>
            <div class="head-item" @click="goCompare">
                <p class="title"><i class="icon"></i><span>{{$t("pe.reportContrast")}}</span></p>
                <p class="txt">{{$t("pe.subMedical2")}}</p>
                <div class="header-btn">
                    {{$t("pe.promotlyContrast")}}
                </div>
            </div>
        </div>
        <div class="List-box">
            <div class="list-title">
                <p class="title">{{$t("pe.myReport")}}</p>
            </div>
            <div class="list-mian" :class="{'no-main':medicalReportList.length == 0}">
              
                <div class="list-item" v-for="(item,index) in medicalReportList" :key="index" @click="goDetails(item)" :class="item.gender==2?'womanColor':'manColor'">
                    <!-- <van-checkbox v-model="item.checktype" shape="square">&nbsp;</van-checkbox> -->
                    <p class="text">{{item.peDate}}</p>
                    <p class="text">{{item.tenantName}}</p>
                    <p class="text">{{item.peCode}}</p>
                    <div class="postName">
                        <span class="name">{{item.peUserName}}</span>
                        <p class="sex"><i :class="item.gender==2?'female':'male'"></i><span>{{item.gender==2?$t("pe.female"):$t("pe.male")}}</span></p>
                    </div>
                    <img v-if="item.isNew == 1" class="new-icon" src="@/assets/img/isnew_icon.png">
                </div>
                <div class="no-data" v-show="medicalReportList.length == 0">
                    <img src="@/assets/img/data-null.png" class="img-null">
                    <p class="text-null">
                        <span >{{$t("pe.noMedicalReport")}}，</span><span @click="seeSearch"  class="btn">{{$t("pe.goQuery")}}</span>
                    </p>
                </div>
            </div>
        </div>
        <!-- <van-overlay :show="loading"/> -->
        <van-overlay :show="overlayShow">
            <div class="searchOverlay" @click.stop>
                <img class="head_icon" src="@/assets/img/tj_search_head_Icon.png">
                <div class="ipt-item">
                    <img class="icon" :src="require('@/assets/img/'+(!dialogData.peUserName?'search_name_iconnull':'search_name_icon')+'.png')"/>
                    <van-field type="text"  class="inputs"  v-model="dialogData.peUserName"  clearable  :placeholder="$t('pe.belongsName')" />
                </div>
                <div class="ipt-item">
                    <img class="icon" :src="require('@/assets/img/'+(!isPhoneNumber(dialogData.peUserTel)?'search_phone_iconnull':'search_phone_icon')+'.png')"/>
                    <van-field type="number"  class="inputs" @blur="!isPhoneNumber(dialogData.peUserTel)?dialogData.peUserTel='':''" v-model="dialogData.peUserTel" :maxlength="11" clearable  :placeholder="$t('pe.belongsPhone')" />
                </div>
                <div class="ipt-item last-item">
                    <img class="icon" :src="require('@/assets/img/'+(!dialogData.telVerifyCode?'tj_checkCode_iconnull':'tj_checkCode_icon')+'.png')"/>
                    <van-field type="number"  class="inputs"  v-model="dialogData.telVerifyCode" :maxlength="6" clearable  :placeholder="$t('pe.verificationCode')" />
                    <button class="btn" @click="SecondaryVerification">{{!codeNum?$t("pe.sendverificatCode"):((codeNum>0)&&(codeNum<=$store.getters.yzmTime)?codeNum+'s':$t("pe.regain"))}}</button>
                </div>
                <button @click="getSearchData" class="btn-search">{{$t("pe.verificationQuery")}}</button>
                <div class="close-box">
                    <img @click="closeOvelay" src="@/assets/img/tj_close_icon.png">
                </div>
            </div>
        </van-overlay>
        <verification ref="childverification" :tel="dialogData.peUserTel" v-if="verciftionShow" :verciftionclose="verciftionclose"/>
    </div>
</template>

<script>
import { medicalReportList, medicalReportSearch } from "@/api/medicalReport";
import { isPhoneNumber } from "@/utils/vaildate";
import verification from '../../components/verification.vue';
export default {
  components: { verification },
    data() {
        return {
            medicalReportList:[],
            // 查询弹层
            overlayShow:false,
            dialogData:{},
            time:60*1000,
            codeNum:null,
            verciftionShow:false,
            // 对比展示
            checkShow:false,
            times:null,
            isPhoneNumber:isPhoneNumber,
            // 加载层
            // loading:true,
        }
    },
    watch:{
        dialogData:{
            handler(newval, oldVal){
                if(newval.peUserTel){
                    if(newval.peUserTel.length>11){
                        this.dialogData.peUserTel = oldVal.peUserTel.substring(0,11);
                    }
                }
            },
            deep:true,
        }
    },
    created(){
        document.title = this.$t("pe.medicalList");
        this.getMedicalReortData();
    },
    methods:{
        verciftionclose(data){
            if(data.type == 1){
                this.verciftionShow = false;
            }
            if(data.imgVerifyCode && data.type == 2){
                this.getCode(data);
            }
            if(data.type == 3){
                this.getCode({})
            }
        },
        async SecondaryVerification(){
            if(!this.dialogData.peUserTel){
                this.$toast(this.$t("pe.pleasePhone"));
                return false;
            }
            this.verciftionShow = true;
        },
        // 获取列表数据
        async getMedicalReortData(){
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            let datadd = {};
            let result = await medicalReportList(datadd);
            toast.clear();
            if(result.data.errorCode == 0){
                for (let i = 0; i < result.data.data.length; i++) {
                    result.data.data[i].checktype = false;
                }
                this.medicalReportList = result.data.data;
            }
            // console.log(result);
            // if(result.)
        },
        goCompare(){
            // this.checkShow = true;
            if(this.medicalReportList.length>2){
                this.$router.push({
                    path:"/pe/compare"
                });
            }else{
                this.$toast(this.$t("pe.nocontrastData"));
            }
        },
        closeOvelay(){
            this.overlayShow = false;
            if(this.times){
                clearInterval(this.times);
                this.codeNum = null;
            }
            this.dialogData = {};
        },
        // 获取验证码
        async getCode(data){
            if(!this.dialogData.peUserTel){
                this.$toast(this.$t("pe.pleasePhone"));
                return false;
            }
            if(!this.codeNum){
                const res =  await this.$axios1.post(
                    '/cus/tel-verify-code-get',
                    { tel: this.dialogData.peUserTel, ...data }
                )
                if(res.errorCode == 100001){
                    this.$toast(res.errorMsg)
                    setTimeout(()=>{
                        this.$refs.childverification.getImgYzmData();
                    },500)
                    return false;          
                }else{
                    this.verciftionShow = false;
                }
                this.codeNum = this.$store.getters.yzmTime;
                this.times = setInterval(()=>{
                    this.codeNum --;
                    if(this.codeNum == 0){
                        this.codeNum = null;
                        clearInterval(this.times);
                    }
                },1000)
            }
        },

        // 获取查询数据
        async getSearchData(){
            if(!this.dialogData.peUserName){
                this.$toast(this.$t("pe.pleaseName"));
                return false;
            }
            if(!this.dialogData.peUserTel){
                this.$toast(this.$t("pe.pleasePhone"));
                return false;
            }
            if(!this.dialogData.telVerifyCode){
                this.$toast(this.$t("pe.pleaseyzm"));
                return false;
            }
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            let result = await medicalReportSearch(this.dialogData);
            toast.clear();
            if(result.data.errorCode == 0){
                // this.medicalReportList = result.data.data;
                if(result.data.data>0){
                    this.getMedicalReortData();
                    this.$toast(this.$t("pe.searchmedical",{num:result.data.data}));
                }else{
                    this.$toast(this.$t("pe.notRequest"));
                }
            }else{
                this.$toast(result.data.errorMsg);
                return false;
            }
            this.overlayShow = false;
            this.dialogData = {};

        },
        // 查看详情
        goDetails(item){
            if(item.reportUrl){
                this.$router.push({
                    path:"/pdfView",
                    query:{
                        pdfUrl:item.reportUrl
                    }
                })
                return
            }
            this.$router.push({
                path:"/pe/detail",
                query:{
                    peCode:item.peCode
                }
            })
        },
        seeSearch(){
            this.overlayShow = true;
        }
    }
}
</script>

<style lang="scss" scoped>
.medicalReport-main{
    background-color: #ECEEF4;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    .main-header{
        box-sizing: border-box;
        padding: .6rem;
        background: #fff;
        margin-bottom: .2rem;
        display: flex;
        .head-item{
            height: 3.8rem;
            flex: 1;
            margin-right: .4rem;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            padding: .4rem;
            position: relative;
            box-sizing: border-box;
            &:first-child{
                background-image: url("~@/assets/img/tj_head_back_left.png");
                
            }
            &:last-child{
                margin: 0;
                background-image: url("~@/assets/img/tj_head_back_right.png");
                .title{
                    text-shadow: 0 .04rem 0 rgba(248, 79, 57, 0.91);
                    .icon{
                        background: url("~@/assets/img/tj_title_right_icon.png") no-repeat;
                        background-size: 100% 100%;
                        box-shadow: 0 .04rem .2rem 0 rgba(250, 139, 125, 0.91);
                    }
                }
                .txt{
                    color: #FFE4DA;
                }
                .header-btn{
                    color: #FF7356;
                }
            }
            .title{
                font-size: .64rem;
                font-family: PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                text-shadow: 0 .04rem 0 rgba(127, 193, 255, 0.91);
                display: flex;
                align-items: center;
                .icon{
                    display: inline-block;
                    width: .64rem;
                    height: .64rem;
                    background: url("~@/assets/img/tj_title_left_icon.png") no-repeat;
                    background-size: 100% 100%;
                    margin-right: .15rem;
                    box-shadow: 0 .04rem .2rem 0 rgba(127, 193, 255, 0.91);
                }
            }
            .txt{
                font-size: .44rem;
                font-family: PingFang SC;
                font-weight: 300;
                color: #D9F5FF;
                margin: .15rem 0;
            }
            .header-btn{
                font-size: .44rem;
                font-family: PingFang SC;
                font-weight: 400;
                color: #52AEFF;
                padding: .15rem .48rem;
                background: #fff;
                width: max-content;
                border-radius: .5rem;
                position: absolute;
                left: .4rem;
                bottom: .4rem;
            }
        }
    }
    .List-box{
        padding: .2rem .6rem;
        flex: 1;
        background-color:#fff;
        display: flex;
        overflow: hidden;
        flex-direction: column;
        .list-title{
            display: flex;
            .title{
                font-size: .64rem;
                font-family: PingFang SC;
                font-weight: 600;
                color: #303133;
                line-height: .64rem;
                margin:.6rem 0;
                padding-left: .3rem;
                position: relative;
                &::after{
                    content: ' ';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    margin-top: -.2rem;
                    width: .1rem;
                    height: .54rem;
                    background: #3988FF;
                    border-radius: 0.15rem;
                }
            }
        }
        .list-mian{
            height: calc(100% - 2.04rem);
            overflow-y: scroll;
            .list-item{
                height: 4.75rem;
                background:url("~@/assets/img/tj_list_item_back.png") no-repeat;
                background-size: 100% 100%;
                margin-bottom: .6rem;
                box-sizing: border-box;
                padding-top: 1.8rem;
                padding-left: 1rem;
                position: relative;
                .text{
                    font-size: .52rem;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #027DC4;
                    line-height: .8rem;
                }
                .postName{
                    display: flex;
                    max-width: max-content;
                    position: absolute;
                    right: .9rem;
                    top: .9rem;
                    font-size: .64rem;
                    font-family: PingFang SC;
                    font-weight: 600;
                    color: #4CAAFF;
                    line-height: .8rem;
                    .name{
                        width: max-content;
                        max-width: 2rem;
                        display: block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        text-align: right;
                        white-space: nowrap;
                    }
                    .male{
                        background-image: url("~@/assets/img/male.png");
                    }
                    .female{
                        background-image: url("~@/assets/img/female.png");
                    }
                    .sex{
                        font-size: .48rem;
                        display: flex;
                        align-items: flex-end;
                        line-height: .7rem;
                        i{
                            width: .4rem;
                            height: .4rem;
                            background-size: 100% 100%;
                            display: block;
                            margin: 0 .4rem;
                            margin-right: .1rem;
                            margin-bottom: .1rem;
                        }
                        
                    }
                }
                .new-icon{
                    position: absolute;
                    width: 2.1rem;
                    height: 1.68rem;
                    bottom: 0.1rem;
                    right: .16rem;
                }
            }
            .womanColor{
                .postName{
                    color: #FF5D9E;
                }
            }
        }
        .no-main{
            display: flex;
            justify-content: center;
            align-items: center;
            .no-data{
                // height: ;
                display: flex;
                align-items: center;
                flex-direction: column;
                .img-null{
                    height: 4.8rem;
                    width: 5.6rem;
                    margin-bottom: 1.4rem;
                }
                .text-null{
                    font-size: .52rem;
                    font-family: PingFang SC;
                    font-weight: 600;
                    color: #C1C4CB;
                    line-height: .8rem;
                    .btn{
                        color: #5C9DF8;
                    }
                }
            }
        }
    }
    .van-overlay{
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9;
    }
    .searchOverlay{
        // position: relative;
        background: #fff;
        border-radius: .6rem;
        padding: 0 .8rem .8rem  .8rem;
        position: relative;
        .head_icon{
            // position: absolute;
            margin: 0 auto;
            margin-top: -2.62rem;
            // box-sizing: border-box;
            width: 5.24rem;
            height: 4.6rem;
            display: block;
            padding: .32rem 0;
        }
        .ipt-item{
            width: 9.72rem;
            display: flex;
            align-items: center;
            margin-top: .9rem;
            // height: .1;
            border-bottom:  .04rem solid #ECEEF4;
            &:nth-child(2){
                margin-top: 0;
            }
            .icon{
                width: .56rem;
                height: auto;
                margin-right: .3rem;
                margin-top: -.1rem;
            }
            .inputs{
                flex: 1;
                font-size: .52rem;
                font-family: PingFang SC;
                font-weight: bold;
                color: #606266;
                line-height: .8rem;
                padding: .4rem 0;
                .van-field__control::-webkit-input-placeholder{
                    color: #C1C4CB;
                    font-weight: 400;
                }
            }
            
        }
        .last-item{
            position: relative;
            .inputs{
                padding-right: 5.5rem;
                max-width: 100%;
                overflow: hidden;
            }
            .btn{
                font-size: .52rem;
                font-family: PingFang SC;
                font-weight: bold;
                color: #49A7FF;
                position: absolute;
                line-height: .8rem;
                background: none;
                border: .02rem solid #49A7FF;
                right: 0;
                padding: .15rem 0;
                border-radius: .8rem;
                width: 3.5rem;
            }
        }
        .btn-search{
            width: 7.4rem;
            height: 1.6rem;
            background: #49A7FF;
            border-radius: .8rem;
            margin: 0 auto;
            margin-top: 1.2rem;
            font-size: .64rem;
            font-family: PingFang SC;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 1.6rem;
            border: 0;
            display: block;
        }
        .close-box{
            position: absolute;
            left: 0;
            bottom: -2.5rem;
            width: 100%;
            display: flex;
            justify-content: center;
            img{
                width: .84rem;
                height: .84rem;
                padding: .5rem;
            }
        }
    }
}
</style>
<style lang="scss">
    .inputs{
        .van-field__control::-webkit-input-placeholder{
            color: #C1C4CB;
            font-weight: 400;
        }
    }
</style>